export const repositoryItems = {
  "account-nature": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: true,
        text: "Business Segment Code",
        value: "businessSegmentCode",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "account-nature",
    },
    title: "Account Nature",
    type: "account-nature",
  },
  branch: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Branch",
        value: "branch",
      },
      {
        text: "Branch Code",
        value: "branchCode",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "Division",
        value: "division",
      },
      {
        text: "Banking Mode",
        value: "bankingMode",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "branch",
    },
    title: "Address Branch",
    type: "branch",
  },
  "cbs-product": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Code",
        value: "code",
      },
      {
        text: "Account Type",
        value: "accountType",
      },
      {
        text: "Assisted",
        value: "assisted",
      },
      // {
      //   text: "Channel",
      //   value: "channel",
      // },
      {
        text: "Banking Mode",
        value: "bankingMode",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "cbs-product",
    },
    title: "Products",
    type: "cbs-product",
  },
  "account-currency": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "account-currency",
    },
    title: "Account Currency",
    type: "account-currency",
  },
  "banking-mode": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "banking-mode",
    },
    title: "Banking Modes",
    type: "banking-mode",
  },
  "service-type": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "service-type",
    },
    title: "Service Types",
    type: "service-type",
  },
  country: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "country",
    },
    title: "Country",
    type: "country",
  },
  customer: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "customer",
    },
    title: "Customer Types",
    type: "customer",
  },
  gender: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "gender",
    },
    title: "Gender",
    type: "gender",
  },
  "identity-doc": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "identity-doc",
    },
    title: "Identity Documents",
    type: "identity-doc",
  },
  "ip-type": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "ip-type",
    },
    title: "IP Types",
    type: "ip-type",
  },
  location: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "location",
    },
    title: "Location",
    type: "location",
  },
  "income-source": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "income-source",
    },
    title: "Income Sources",
    type: "income-source",
  },
  "nominee-relationship": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "nominee-relationship",
    },
    title: "Nominee Relationships",
    type: "nominee-relationship",
  },
  onboarding: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "onboarding",
    },
    title: "Onboarding Types",
    type: "onboarding",
  },
  "place-of-birth": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "place-of-birth",
    },
    title: "Place Of Birth",
    type: "place-of-birth",
  },
  "post-code": {
    headers: [
      {
        text: "Post Code",
        value: "postCode",
      },
      {
        text: "Sub-office",
        value: "subOffice",
      },
      {
        text: "Thana",
        value: "thana",
      },
      {
        text: "Thana Code",
        value: "thanaCode",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "District Code",
        value: "districtCode",
      },
      {
        text: "Division",
        value: "division",
      },
      {
        text: "Division Code",
        value: "divisionCode",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "post-code",
    },
    title: "Address Post Code",
    type: "post-code",
  },
  profession: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Category",
        value: "category",
      },
      {
        text: "Sub Category",
        value: "subCategory",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Sector Code",
        value: "sectorCode",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "profession",
    },
    title: "Professions",
    type: "profession",
  },
  "residence-status": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Code",
        value: "code",
      },
      {
        sortable: true,
        text: "Customer Category",
        value: "customerCategory",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "residence-status",
    },
    title: "Residence Status",
    type: "residence-status",
  },
  "risk-country": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "risk-country",
    },
    title: "Risk Country Types",
    type: "risk-country",
  },
  "risk-grading-product": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "risk-grading-product",
    },
    title: "Risk Grading Product",
    type: "risk-grading-product",
  },
  "source-of-fund": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "source-of-fund",
    },
    title: "Source of Fund Types",
    type: "source-of-fund",
  },
  terms: {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Channel",
        value: "channel",
      },
      {
        text: "Terms",
        value: "terms",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.terms",
    title: "Terms & Condition",
    type: "terms",
  },
  "channel-text": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "textId",
      },
      {
        text: "Channel",
        value: "channel",
        width: 100,
      },
      {
        text: "Text",
        value: "text",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "channel-text",
    },
    title: "Channel Text",
    type: "channel-text",
  },
  "transaction-amount": {
    headers: [
      {
        align: "start",
        sortable: true,
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        sortable: false,
        text: "Actions",
        value: "actions",
        width: 120,
      },
    ],
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: {
      item: "transaction-amount",
    },
    title: "Transaction Amount Types",
    type: "transaction-amount",
  },
};
