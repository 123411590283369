<template>
  <v-container>
    <v-row justify="center" class="text-center">
      <v-col cols="12">
        <h4 class="text-h4 font-weight-regular">Terms and Conditions</h4>
      </v-col>
    </v-row>

    <v-row justify="center" class="text-center" v-for="term in dataList" :key="term.id">
      <v-col cols="12">
        <TermsItem :term="term" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { repositoryItems } from "@/constants/repositoryItems";
import { REPO_ITEM_LIST } from "@/constants/api";

export default {
  name: "RepositoryItem",
  metaInfo: { title: "Terms and Conditions" },
  components: {
    TermsItem: () => import("@/views/components/Repository/TermsItem"),
  },
  data() {
    return {
      loading: false,
      dataList: [],
      search: null,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          REPO_ITEM_LIST.replace(":item:", repositoryItems["terms"].type)
        );
        if (!response || !response.data || response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.dataList = response.data.data;
      } catch (err) {
        this.$iziToast.showError(err);
        return this.$router.push({ name: "repository.index" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
